<script>
import accountReceivableService from '@/services/master/account-receivable'

import MenuBar from './components/MenuBar.vue'

import CreateEditReadForm from '../components/CreateEditReadForm.vue'

export default {
  components: {
    CreateEditReadForm,

    MenuBar
  },
  data() {
    return {
      id: this.$route.params.id,
      accountReceivable: {}
    }
  },
  created() {
    this.fetchAccountReceivableById(this.id)
  },
  methods: {
    async fetchAccountReceivableById(id) {
      try {
        const res = await accountReceivableService.getAccountReceivableById(id)
        this.accountReceivable = { ...res.data.data }
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขลูกค้าลูกหนี้"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <create-edit-read-form
          :modeProp="'EDIT'"
          :accountReceivableProp="accountReceivable"
          class="mb-3"
        ></create-edit-read-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
