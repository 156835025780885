<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import AppCard from '@/components/card/Card.vue'
import { toastMixins } from '@/mixins'
import accountReceivableService from '@/services/master/account-receivable'

export default {
  mixins: [toastMixins],
  props: {
    modeProp: {
      type: String,
      required: true,
      validator: (value) => {
        return ['CREATE', 'EDIT'].includes(value)
      },
      default: 'READ'
    },
    accountReceivableProp: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  components: { AppCard },
  data() {
    return {
      accountReceivable: {
        customerId: null,
        customerCategoryId: '11311',
        customerName: null,
        contact: null,
        address1: null,
        address2: null,
        province: null,
        zipCode: null,
        telephoneNumber: null,
        taxId: null
      },
      customerCategoryIdOptions: [
        { text: '1:ลูกหนี้การค้า', value: '11311' },
        { text: '2:ลูกหนี้ อื่น๑', value: '11312' },
        { text: '3:ลูกหนี้ อื่น๒', value: '11311' }
      ],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    }),
    shouldSaveButtonEnable() {
      switch (this.modeProp) {
        case 'CREATE':
          const requiredFields = ['customerId', 'customerCategoryId']
          return _.values(_.pick(this.accountReceivable, requiredFields)).every(
            (v) => !_.isNil(v) && _.isString(v) && v.trim().length > 0
          )
        case 'EDIT':
          return _.keys(this.accountReceivable).some(
            (key) =>
              this.accountReceivable[key] !== this.accountReceivableProp[key]
          )
        default:
          return false
      }
    }
  },
  watch: {
    accountReceivableProp() {
      if (!_.isEmpty(this.accountReceivableProp)) {
        _.keys(this.accountReceivable).forEach((key) => {
          const newValue = this.accountReceivableProp[key]
          this.accountReceivable[key] = newValue
        })
      }
    }
  },
  methods: {
    async saveButtonClicked() {
      switch (this.modeProp) {
        case 'CREATE':
          try {
            const args = this.getDataArgs()
            await accountReceivableService.createNewAccountReceivable(args)
            this.mxDisplaySuccessMessage('เพิ่มลูกค้าลูกหนี้รายใหม่สำเร็จ')
            setTimeout(() => {
              this.$router.back()
            }, this.defaultDelayMs)
          } catch (err) {
            this.errMsg =
              err.response?.data?.thMessage ||
              'เกิดข้อผิดพลาดในการเพิ่มลูกค้าลูกหนี้'
          }
          break
        case 'EDIT':
          try {
            const args = this.getDataArgs()
            await accountReceivableService.updateAccountReceivableById(
              this.$route.params.id,
              args
            )
            this.mxDisplaySuccessMessage('แก้ไขลูกค้าลูกหนี้รายเดิมสำเร็จ')
            setTimeout(() => {
              this.$router.back()
            }, this.defaultDelayMs)
          } catch (err) {
            this.errMsg =
              err.response?.data?.thMessage ||
              'เกิดข้อผิดพลาดในการแก้ไขลูกค้าลูกหนี้'
          }
          break
        default:
          break
      }
    },
    getDataArgs() {
      switch (this.modeProp) {
        case 'CREATE':
          const createArgs = {}
          _.keys(this.accountReceivable).forEach((key) => {
            const value = this.accountReceivable[key]
            if (_.isString(value)) {
              const trimValue = value.trim()
              if (trimValue.length > 0) {
                createArgs[key] = trimValue
              } else {
                createArgs[key] = null
              }
            } else {
              createArgs[key] = value
            }
          })
          return createArgs
        case 'EDIT':
          const updateArgs = {}
          _.keys(this.accountReceivable).forEach((key) => {
            const newValue = this.accountReceivable[key]
            const oldValue = this.accountReceivableProp[key]
            if (newValue !== oldValue) {
              if (_.isString(newValue)) {
                const trimValue = newValue.trim()
                if (trimValue.length > 0) {
                  updateArgs[key] = trimValue
                } else {
                  updateArgs[key] = null
                }
              } else {
                updateArgs[key] = newValue
              }
            }
          })
          return updateArgs
        default:
          return {}
      }
    },
    cancelButtonClicked() {
      this.$router.back()
    },
    getClassStyles(type, payload = {}) {
      switch (type) {
        case 'DIV_INPUT':
          let { marginBottom } = payload
          if (_.isNil(marginBottom)) marginBottom = true
          return `d-flex align-items-center pr-3 ${marginBottom ? 'mb-3' : ''}`
        case 'SPAN_LABEL':
          return 'text-nowrap mr-3'
      }
    }
  }
}
</script>

<template>
  <app-card>
    <!-- buttons -->
    <b-row>
      <b-col
        cols="12"
        lg="6"
        class="d-flex align-items-center justify-content-center justify-content-lg-start text-lg font-weight-bold mb-3 mb-lg-0"
      >
        <span>
          {{ modeProp === 'CREATE' ? 'เพิ่ม' : 'แก้ไข' }}ลูกค้าลูกหนี้
        </span>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        class="d-flex justify-content-center justify-content-lg-end"
      >
        <b-button
          variant="primary"
          class="px-5 mr-3"
          @click="saveButtonClicked()"
          :disabled="!shouldSaveButtonEnable"
        >
          บันทึก
        </b-button>
        <b-button
          variant="outline-primary"
          class="px-5 mr-0 mr-lg-3"
          @click="cancelButtonClicked()"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>

    <!-- line seperator -->
    <b-row>
      <b-col>
        <div class="py-2"></div>
      </b-col>
    </b-row>

    <!-- รายละเอียดข้อมูลลูกค้าลูกหนี้ -->
    <b-row>
      <!-- title -->
      <b-col cols="12" class="my-3">
        <span class="text-lg font-weight-bold">
          รายละเอียดข้อมูลลูกค้าลูกหนี้
        </span>
      </b-col>

      <!-- customerId -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <span :class="`${getClassStyles('SPAN_LABEL')}`">รหัสลูกค้า</span>
          <b-input
            v-model="accountReceivable.customerId"
            :disabled="['EDIT', 'READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- customerName -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <span :class="`${getClassStyles('SPAN_LABEL')}`">ชื่อลูกค้า</span>
          <b-input
            v-model="accountReceivable.customerName"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- contact -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <span :class="`${getClassStyles('SPAN_LABEL')}`">ติดต่อกับ</span>
          <b-input
            v-model="accountReceivable.contact"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- address1 -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <div class="d-flex flex-column mr-3">
            <span :class="`${getClassStyles('SPAN_LABEL')}`">ที่อยู่</span>
            <span class="text-sm text-nowrap">(เลขที่, ถนน)</span>
          </div>
          <b-input
            v-model="accountReceivable.address1"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- address2 -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <div class="d-flex flex-column mr-3">
            <span :class="`${getClassStyles('SPAN_LABEL')}`">ที่อยู่</span>
            <span class="text-sm text-nowrap">(ตำบล, อำเภอ)</span>
          </div>
          <b-input
            v-model="accountReceivable.address2"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- province / zipCode -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <div class="d-flex flex-column mr-3">
            <span :class="`${getClassStyles('SPAN_LABEL')}`">ที่อยู่</span>
            <span class="text-sm text-nowrap">(จังหวัด, รหัสฯ)</span>
          </div>
          <b-input
            class="mr-3"
            v-model="accountReceivable.province"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
          <b-input
            v-model="accountReceivable.zipCode"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- telephoneNumber -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <span :class="`${getClassStyles('SPAN_LABEL')}`">โทรศัพท์</span>
          <b-input
            v-model="accountReceivable.telephoneNumber"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>

      <!-- taxId -->
      <b-col cols="12">
        <div :class="`${getClassStyles('DIV_INPUT')}`">
          <span :class="`${getClassStyles('SPAN_LABEL')}`">
            หมายเลขผู้เสียภาษี
          </span>
          <b-input
            v-model="accountReceivable.taxId"
            :disabled="['READ'].includes(modeProp)"
          ></b-input>
        </div>
      </b-col>
    </b-row>

    <b-row class="m-0 p-0">
      <b-col cols="12" lg="6" class="m-0 p-0">
        <!-- รายละเอียดทางบัญชี 1 -->
        <b-row>
          <!-- title -->
          <b-col cols="12" class="mt-5 mb-3">
            <span class="text-lg font-weight-bold"> รายละเอียดทางบัญชี 1 </span>
          </b-col>

          <!-- customerCategoryId -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">ประเภท</span>
              <b-select
                :options="customerCategoryIdOptions"
                v-model="accountReceivable.customerCategoryId"
                :disabled="['EDIT', 'READ'].includes(modeProp)"
              ></b-select>
            </div>
          </b-col>

          <!-- cumulativeType -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`"
                >สะสมรายการแบบ</span
              >
              <b-select disabled></b-select>
            </div>
          </b-col>

          <!-- creditTerm -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                ระยะเวลาให้เครดิต (วัน)
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- creditAmount -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                วงเงินให้เครดิต
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- leftCredit -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                เครดิตคงเหลือ
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="6" class="m-0 p-0">
        <!-- รายละเอียดทางบัญชี 2 -->
        <b-row>
          <!-- title -->
          <b-col cols="12" class="mt-5 mb-3">
            <span class="text-lg font-weight-bold"> รายละเอียดทางบัญชี 2 </span>
          </b-col>

          <!-- customerCategory -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                หมวดลูกค้า
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- spendIn / discountPercent -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                หากชำระภายใน (วัน)
              </span>
              <b-input class="mr-3" disabled></b-input>
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> ลด (%) </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- exceedInterestPercent -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                ดอกเบี้ยเกินกำหนด (%)
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- pricePercent / productPrice -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`text-nowrap mr-2`"> ราคา </span>
              <b-input disabled class="mr-2"></b-input>
              <span :class="`text-nowrap mr-2`"> % ของราคาขายสินค้า </span>
              <b-select disabled></b-select>
            </div>
          </b-col>

          <!-- discount1 -->
          <b-col cols="12">
            <div
              :class="`${getClassStyles('DIV_INPUT', { marginBottom: false })}`"
            >
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                ลด 1: ส่วนลดระดับ 1
              </span>
              <b-input disabled class="mr-3"></b-input>
              <span class="text-nowrap"> ในรายการสินค้า </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="m-0 p-0">
      <b-col cols="12" lg="6" class="m-0 p-0">
        <!-- รายละเอียดการวางบิล -->
        <b-row>
          <!-- title -->
          <b-col cols="12" class="mt-5 mb-3">
            <span class="text-lg font-weight-bold"> รายละเอียดการวางบิล </span>
          </b-col>

          <!-- billBefore -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                วางบิลก่อนครบกำหนด (วัน)
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- billType -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`text-nowrap mr-2`"> เปิดวางบิลให้ตาม </span>
              <b-select disabled></b-select>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="6" class="m-0 p-0">
        <!-- รายละเอียดอื่น ๆ ทั่วไป -->
        <b-row>
          <!-- title -->
          <b-col cols="12" class="mt-5 mb-3">
            <span class="text-lg font-weight-bold">
              รายละเอียดอื่น ๆ ทั่วไป
            </span>
          </b-col>

          <!-- sellerId -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`"
                >รหัสพนักงานขาย</span
              >
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- customerLevel -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                จัดชั้นลูกค้า
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>

          <!-- transportation -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> ขนส่ง </span>
              <b-select disabled></b-select>
            </div>
          </b-col>

          <!-- fromInventory / fromInventoryAddress -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> จากคลัง </span>
              <b-select class="mr-3" disabled></b-select>
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> ที่อยู่ </span>
              <b-select disabled></b-select>
            </div>
          </b-col>

          <!-- toInventory / toInventoryAddress -->
          <b-col cols="12">
            <div :class="`${getClassStyles('DIV_INPUT')}`">
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> ไปคลัง </span>
              <b-select class="mr-3" disabled></b-select>
              <span :class="`${getClassStyles('SPAN_LABEL')}`"> ส่งที่ </span>
              <b-select disabled></b-select>
            </div>
          </b-col>

          <!-- currency -->
          <b-col cols="12">
            <div
              :class="`${getClassStyles('DIV_INPUT', { marginBottom: false })}`"
            >
              <span :class="`${getClassStyles('SPAN_LABEL')}`">
                สกุลเงินที่ใช้
              </span>
              <b-input disabled></b-input>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- สรุปยอดหนี้ลูกหนี้ -->
    <b-row v-if="['EDIT'].includes(modeProp)" class="m-0 p-0">
      <!-- title -->
      <b-col cols="12" class="m-0 p-0 mt-5 mb-3">
        <span class="text-lg font-weight-bold"> สรุปยอดหนี้ลูกหนี้ </span>
      </b-col>
      <!-- content -->
      <b-col cols="12" class="m-0 p-0">
        <b-row class="m-0 p-0">
          <!-- ฝั่งซ้าย -->
          <b-col cols="12" lg="6" class="m-0 p-0">
            <b-row>
              <!-- previousDebtBalance -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`"
                    >หนี้คงค้างยกมา</span
                  >
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- cumulativeDebt -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`"
                    >หนี้สะสมทั้งปี</span
                  >
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- paidAmount -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`"
                    >ชำระแล้วทั้งปี</span
                  >
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- allDebtBalance -->
              <b-col cols="12">
                <div
                  :class="`${getClassStyles('DIV_INPUT', {
                    marginBottom: false
                  })}`"
                >
                  <span :class="`${getClassStyles('SPAN_LABEL')}`">
                    หนี้คงค้างทั้งสิ้น
                  </span>
                  <b-input disabled></b-input>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <!-- ฝั่งขวา -->
          <b-col cols="12" lg="6" class="m-0 p-0 mt-3 mt-lg-0">
            <b-row>
              <!-- latestSellDate -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`">
                    วันที่ขายล่าสุด
                  </span>
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- latestSellRound -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`">
                    ขายครั้งล่าสุด
                  </span>
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- latestReceiveMoneyDate -->
              <b-col cols="12">
                <div :class="`${getClassStyles('DIV_INPUT')}`">
                  <span :class="`${getClassStyles('SPAN_LABEL')}`">
                    รับเงินล่าสุด
                  </span>
                  <b-input disabled></b-input>
                </div>
              </b-col>

              <!-- latestPaidAmount -->
              <b-col cols="12">
                <div
                  :class="`${getClassStyles('DIV_INPUT', {
                    marginBottom: false
                  })}`"
                >
                  <span :class="`${getClassStyles('SPAN_LABEL')}`">
                    ยอดชำระล่าสุด
                  </span>
                  <b-input disabled></b-input>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </app-card>
</template>

<style scoped></style>
